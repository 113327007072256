import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'


const Header = () => {
  return (
    
    <div name="home" className='w-full h-screen bg-[#282828]'>
        <div>
        <script><script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/0.155.0/three.min.js" integrity="sha512-auFmPm+de880vk/c+bgBmuOnQ7BM28z7zPWmepVgX8737t75OHZjElOnQqERwP8cE4DIyCJ+gb1kE0b/eLWcnA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script></script>
        <script src="three.r134.min.js"></script>
        <script src="vanta.birds.min.js"></script>
        </div>
      

      

        

        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
    <p className='text-white'>Hey there, I'm</p>
    <h1 className='text-4xl sm:text-7xl text-white'>Drake Hooks</h1>
    <h2 className='text-white'>I'm a developer focused on technology and solutions.</h2>
    <div>
        <button className='text-white group border-2 px-5 py-2 my-2 flex items-center hover:bg-[#3498DB] hover:border-[#3498DB]'>View Work 
    <span className='group-hover:translate-x-3 duration-300'>
    <HiArrowNarrowRight className='ml-2 hover:' />
    </span>
    </button>
    </div>
  
  
    </div>


    </div>

    


  )
}

export default Header
