import About from "../components/About";
import Header from "../components/Header";
import Navbar from "../components/navbar";
import Skills from "../components/skills";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {



  return (
    

      
    <div>
      <Navbar />
      <Header />
      <About />
      <Skills />
    </div>
  );
}

export default App;
