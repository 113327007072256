import Navbar from '../components/navbar';


import React from 'react';

const Contact = () => {
  const containerStyle = {
    backgroundColor: '#282828',
    minHeight: '100vh',
    maxWidth: '100vw',
    margin: '0 auto',
    padding: '5%',
  };

  const formStyle = {
    maxWidth: '500px',
    margin: '0 auto',
    textAlign: 'left',
    padding: '20px 0',
  };

  const inputStyle = {
    display: 'block',
    margin: '0 auto',
    width: '100%',
    color: 'white',
    background: 'transparent',
    border: '0',
    borderBottom: '2px solid #828282',
    fontSize: '18px',
    padding: '10px',
  };

  const submitButtonStyle = {
    backgroundColor: '#545557',
    padding: '15px 0',
    color: 'white',
    fontSize: '18px',
    borderBottom: 'none',
    marginTop: '30px',
    cursor: 'pointer',
    transition: 'all .3s ease',
  };

  const submitButtonHoverStyle = {
    background: '#FFF',
    color: '#1b1c1e',
  };
  return (

    <><div>
      <Navbar />
    </div>
    
    <div style={containerStyle}>
      <h1 style={{ fontFamily: 'Montserrat, serif', fontSize: '59px', color: 'white', fontWeight: 600, paddingBottom: '20px' }}>
        
      </h1>
      <p style={{ color: 'white' }}>
        
      </p>
      <form action="contact.php" method="POST" style={formStyle}>
        <label style={{ fontFamily: 'Montserrat' }} htmlFor="name">Name</label>
        <input style={inputStyle} type="text" name="name" id="name" />
        <label style={{ fontFamily: 'Montserrat' }} htmlFor="email">Email</label>
        <input style={inputStyle} type="text" name="email" id="email" />
        <label style={{ fontFamily: 'Montserrat' }} htmlFor="subject">Subject</label>
        <input style={inputStyle} type="text" name="subject" id="subject" />
        <label style={{ fontFamily: 'Montserrat' }} htmlFor="message">Message</label>
        <textarea style={inputStyle} name="message" id="message" cols="30" rows="10"></textarea>
        <input
          style={submitButtonStyle}
          type="submit"
          value="Submit"
          onMouseEnter={(e) => e.target.style = { ...submitButtonStyle, ...submitButtonHoverStyle }}
          onMouseLeave={(e) => e.target.style = submitButtonStyle}
        />
      </form>
    </div></>
  );
};

export default Contact;
