import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link as ScrollLink } from 'react-scroll'; // Import Link from react-scroll and give it an alias
import { Link as RouterLink } from 'react-router-dom'; // Import Link from react-router-dom

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className='fixed w-full h-[90px] text-xl flex justify-between items-center px-5 bg-[#212529] text-[#3498DB]'>
      <div>
        {/* ... */}
      </div>

      {/* Menu */}
      <ul className="hidden md:flex">
        <li>
          <RouterLink
            to="/home" style={{ transition: 'color 0.9s' }} className="hover:text-white"
          >
            Home
          </RouterLink>
        </li>

        <li>
          <ScrollLink
            to='software' smooth={true} duration={500} style={{ transition: 'color 0.9s' }} className="hover:text-white"
          >
            Software
          </ScrollLink>
        </li>
        <li>
          <RouterLink
            to="/contact" style={{ transition: 'color 0.9s' }} className="hover:text-white"
          >
            Contact
          </RouterLink>
        </li>
        <li>
          <ScrollLink
            to='about'
            smooth={true}
            duration={500}
            style={{ transition: 'color 0.9s' }}
            className="hover:text-white"
          >
            About
          </ScrollLink>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>


      {/* Mobile Menu */}
      <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#212529] flex flex-col justify-center items-center'}>
        <li className='py-6 text-4xl'>
          <RouterLink onClick={handleClick} to="/home">
            Home
          </RouterLink>
        </li>
        <li className='py-6 text-4xl'>
          <ScrollLink onClick={handleClick} to='software' smooth={true} duration={500}>
            Software
          </ScrollLink>
        </li>
        <li className='py-6 text-4xl'>
          <RouterLink onClick={handleClick} to="/contact">
            Contact
          </RouterLink>
        </li>
        <li className='py-6 text-4xl'>
          <ScrollLink onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </ScrollLink>
        </li>
      </ul>

      {/* ... */}
    </div>
  );
};

export default Navbar;
